// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/Education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/Experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-chip-8-js": () => import("./../../../src/pages/Projects/Chip8.js" /* webpackChunkName: "component---src-pages-projects-chip-8-js" */),
  "component---src-pages-shopify-js": () => import("./../../../src/pages/Shopify.js" /* webpackChunkName: "component---src-pages-shopify-js" */)
}

